import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async'; // HelmetProvider 추가
import Header from './components/Header'; // 공통 헤더
import Footer from './components/Footer'; // 공통 푸터
import HomePage from './pages/HomePage'; // 홈 페이지
import LifePage from './pages/LifePage'; // 생활 효율 페이지
import PveEfficiencyPage from './pages/PvePage'; // PVE 효율 페이지
import LifePageInfo from './pages/Info/LifePageInfo';
import PvePageInfo from './pages/Info/PvePageInfo';
import LumberingInfo from './pages/Info/LumberingInfo';
import LifeToolPage from './pages/LifeToolPage'; // 생활 도구 페이지
import LocalStorageManager from './pages/LocalStorageManager'; // 쿠키 관리
import ConversionPage from './pages/ConversionPage';
import './App.css'; // 전체 스타일

function App() {
  return (
    <HelmetProvider> {/* HelmetProvider로 전체 앱 감싸기 */}
      {/* 공통 헤더 */}
      <Header />

      {/* 메인 콘텐츠 */}
      <div className="app-content">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/life-data" element={<LifePage />} />
          <Route path="/life-data/info" element={<LifePageInfo />} />
          <Route path="/pve-data" element={<PveEfficiencyPage />} />
          <Route path="/pve-data/info" element={<PvePageInfo />} />
          <Route path="/life-tool/lumber/info" element={<LumberingInfo />} />
          <Route path="/life-tool/:toolType" element={<LifeToolPage />} />
          <Route path="/storage" element={<LocalStorageManager />} />
          <Route path="/conversion" element={<ConversionPage />} />
        </Routes>
      </div>

      {/* 공통 푸터 */}
      <Footer />
    </HelmetProvider>
  );
}

export default App;
