import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { fetchPveEfficiencyData } from '../services/LostArkApi';
import DynamicButtonGroup from '../components/ui/DynamicButtonGroup';
import PveTable from '../components/ui/PveTable';
import '../styles/EfficiencyPage.css';

const PvePage = () => {
  const [originalData, setOriginalData] = useState([]); // 원본 데이터
  const [data, setData] = useState([]); // 표시할 데이터
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sorted, setSorted] = useState(false);
  const [fadeClass, setFadeClass] = useState('fade-in');

  useEffect(() => {
    const loadData = async () => {
      try {
        const fetchedData = await fetchPveEfficiencyData();
        setOriginalData(fetchedData); // 원본 데이터 저장
        setData(fetchedData); // 기본 데이터 설정
        setFadeClass('fade-in active');
      } catch (err) {
        setError('데이터를 불러오는 중 오류가 발생했습니다.');
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 100);
      }
    };

    loadData();
  }, []);

  const handleSort = () => {
    if (sorted) {
      // 원래 순서로 복원
      setData(originalData);
    } else {
      // 시간당 골드 기준으로 정렬
      setData([...data].sort((a, b) => b.gold_per_hour - a.gold_per_hour));
    }
    setSorted(!sorted); // 정렬 상태 토글
  };

  const buttons = [
    {
      label: sorted ? '원래대로' : '시간당 골드 정렬',
      onClick: handleSort,
      className: 'sort-button',
    },
  ];

  const pageTitle = 'PVE 효율 계산기';
  const pageDescription =
    'PVE 효율 계산기를 사용하여 컨텐츠의 시간당 골드 수익을 확인하고 정렬하세요. 데이터 정렬 및 효율적인 컨텐츠 확인 기능을 제공합니다.';
  const keywords = 'PVE, 효율 계산기, 시간당 골드, Lost Ark, 아비도스 작전, 스콜라키아, 아게오로스, 돌파석, 골드, 파괴석, 수호석, 운명의';

  if (error) {
    return (
      <div className="efficiency-page fade-in active">
        <Helmet>
          <title>오류 | {pageTitle}</title>
          <meta name="description" content="데이터를 불러오는 중 오류가 발생했습니다." />
        </Helmet>
        <p style={{ color: 'red' }}>{error}</p>
      </div>
    );
  }

  return (
    <div className={`efficiency-page ${fadeClass}`}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content="로스트 골드" />
      </Helmet>
      {!loading && (
        <>
          <h1 className="page-title">
            {pageTitle}
            <Link to="/pve-data/info" className="info-icon" title="PVE 효율 계산기 정보">
              &#9432;
            </Link>
          </h1>
          <DynamicButtonGroup buttons={buttons} />
          {data.map((mission, index) => (
            <PveTable key={index} mission={mission} />
          ))}
        </>
      )}
    </div>
  );
};

export default PvePage;
