import React from 'react';
import '../styles/Footer.css' ;
//© 2024 Lost Ark Efficiency Tracker
function Footer() {
  return (
    <footer className="footer">
      <p></p>
    </footer>
  );
}

export default Footer;
