import React, { useEffect, useState } from 'react';
import { fetchPveEfficiencyData, fetchLifeEfficiencyData } from '../services/LostArkApi';
import '../styles/HomePage.css';

function HomePage() {
  const [mergedData, setMergedData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [pveData, lifeData] = await Promise.all([
          fetchPveEfficiencyData(),
          fetchLifeEfficiencyData(),
        ]);

        const combinedData = [...pveData, ...lifeData]
          .map((content) => ({
            name: content.name,
            gold_per_hour: content.gold_per_hour,
          }))
          .sort((a, b) => b.gold_per_hour - a.gold_per_hour);

        setMergedData(combinedData);
      } catch (error) {
        console.error('데이터를 가져오는 중 오류가 발생했습니다:', error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  if (loading) {
    return <div className="loading-container">데이터를 불러오는 중입니다...</div>;
  }

  return (
    <div className="home-page">
      {/* 상단 타이틀 */}
      <div className="home-header">
        <h1 className="home-title">
          로스트 골드<img src="/clock_4.png" alt="아이콘" className="title-icon" />
        </h1>
        <p className="home-subtitle">시간당 골드 - 한 시간 동안 얻을 수 있는 골드 획득량</p>
      </div>

      {/* 컨텐츠 테이블 */}
      <div className="home-content">
        <table className="home-table">
          <thead>
            <tr>
              <th>컨텐츠명</th>
              <th>시간당 골드</th>
            </tr>
          </thead>
          <tbody>
            {mergedData.map((content, index) => (
              <tr key={index}>
                <td>{content.name}</td>
                <td>{content.gold_per_hour.toLocaleString()} 골드</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default HomePage;
