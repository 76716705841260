import React, { useState, useEffect } from "react";
import "../styles/LocalStorageManager.css";

const LocalStorageManager = () => {
  const [localStorageData, setLocalStorageData] = useState("");

  useEffect(() => {
    const storedData = localStorage.getItem("userInput");
    // 로컬 스토리지 데이터를 줄바꿈(\n)으로 변환
    const formattedData = storedData ? storedData.replace(/,/g, "\n") : "";
    setLocalStorageData(formattedData);
  }, []);

  const handleClearStorage = () => {
    localStorage.removeItem("userInput");
    setLocalStorageData("");
  };

  const handleEditValue = (newValue) => {
    // 줄바꿈을 ,로 변환하여 저장
    const formattedData = newValue.replace(/\n/g, ",");
    localStorage.setItem("userInput", formattedData);
    setLocalStorageData(newValue); // 줄바꿈 형태로 상태 저장
  };

  return (
    <div className="local-storage-manager-container">
      <h1>로컬 스토리지 관리</h1>
      <table className="local-storage-manager-table">
        <thead>
          <tr>
            <th>Key</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>userInput</td>
            <td>
              <textarea
                value={localStorageData}
                onChange={(e) => handleEditValue(e.target.value)}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="local-storage-manager-actions">
        <button className="clear-button" onClick={handleClearStorage}>
          초기화
        </button>
      </div>
    </div>
  );
};

export default LocalStorageManager;
