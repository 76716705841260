import React from 'react';

const HuntingTool = () => {
  return (
    <div>
      <h2 className="text-h2">수렵 도구 계산기</h2>
      <p>아직 준비중입니다. 2024.12.02 ~ 2024.12.31 </p>
      {/* 벌목 전용 입력 및 결과 표시 UI */}
    </div>
  );
};

export default HuntingTool;
